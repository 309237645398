import classNames from 'classnames';
import { ContainerSize, Quote } from '@lib/types';
import Container from '@components/container';
import Image from 'next/image';

export type QuoteSectionProps = {
  data: Quote;
};

export default function QuoteSection({
  data: { title, author, authorDescription },
}: QuoteSectionProps) {
  return (
    <Container size={ContainerSize.wide}>
      <div className={classNames('relative')}>
        <div className="grid grid-cols-12 gap-1.5 md:gap-6">
          <div className="lg:relative col-start-2 col-span-10 lg:col-start-4 lg:col-span-6">
            <div className="absolute left-0 z-[-1] lg:ml-[-60px] mt-[-40px] scale-75 origin-top-left lg:scale-100">
              <Image
                className=""
                alt="Quotation Mark"
                src="/quotation-mark.svg"
                width={179}
                height={146}
              />
            </div>

            <h1 className="text-23 md:text-35 leading-130 font-bold text-blue-800">
              {title}
            </h1>
          </div>
        </div>
      </div>
      {(author || authorDescription) && (
        <div className="grid grid-cols-12 gap-1.5 md:gap-6 mt-10 lg:mt-16">
          <h2 className="text-blue-800 col-start-2 col-span-10 lg:col-start-4 lg:col-span-6">
            {author && <b>{author}</b>}
            {author && authorDescription && ', '}
            {authorDescription}
          </h2>
        </div>
      )}
    </Container>
  );
}
